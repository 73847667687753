















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class HeaderType1 extends Vue {}
