








import { Component, Vue } from 'vue-property-decorator'
import HelloWorld from '@/components/HelloWorld.vue'
import Level2Heading from '@/components/atoms/Level2Heading.vue'
import HeaderItem1 from '@/components/organisms/HeaderType1.vue'

@Component({
  components: {
    HelloWorld,
    Level2Heading,
    HeaderItem1,
  },
})
export default class Test1 extends Vue {}
